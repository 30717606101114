@use "./styles/partials/mixins" as *;
@use "./styles/partials/typography" as *;
@use "./styles/partials/variables" as *;
// boxsizing

*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  font-family: "Karla", Times, serif;
}

html,
body {
  overflow-x: hidden;
}

.aboutContact {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }
}

.contactDesktop {
  display: none;

  @include tablet {
    display: block;
  }
}

.contactMobile {
  display: block;

  @include tablet {
    display: none;
  }
}
