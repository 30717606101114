@use "../../styles/partials/mixins" as *;
@import url("https://fonts.googleapis.com/css?family=Roboto");

$light-pink: #fff0f0;
$pink-border: #b18597;
$dark-pink: #f9c4d2;
$pink-shadow: #ffe3e2;
$pink: #ffe9e9;

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.projects h1 {
  margin: 0;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: #f5f5f5;
  font-family: Roboto;
  padding: 5% 0;
}

h1 {
  font-size: 40pt;
  font-weight: 500;
  color: #363638;
}

.container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
}

.item {
  position: relative;
  float: left;
  overflow: hidden;
  margin: 10px 1%;
  min-width: 320px;
  max-width: 410px;
  width: 100%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  //   border: 3px solid silver; // Silver border to mimic a laptop/monitor
  border-radius: 8px; // Slightly rounded corners
  border: 3px solid rgba(255, 255, 255, 0.5); // Adjust opacity as needed
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); // Softer shadow for a smoother transition
}

.item:hover {
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.item * {
  transition: all 0.35s ease-in-out;
}

.container img {
  max-width: 100%;
  vertical-align: top;
}

.item:hover img {
  opacity: 0.1;
}

.text {
  width: 80%;
  height: 90%;
  position: absolute;
  top: -100px;
  left: 10%;
  color: black;
}

.text h3 {
  color: black;
}

.item:hover .text {
  top: 10%;
  @include tablet {
    top: 20%;
  }
}

.item .button {
  align-items: center;
  color: black;
  display: flex;
  font-size: 15px;
  font-weight: 300;
  justify-content: center;
  line-height: 1em;
  //   max-width: 20%;
  min-width: 140px;
  padding: 1.5rem;
  margin: 0.2rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  background: $light-pink;
  border: 2px solid $pink-border;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);
  position: absolute;
  bottom: -100px; // Start from bottom
  left: 25%; // Center horizontally
  width: 50%;

  position: absolute;
  bottom: -100px; // Start from below
  left: 25%; // Center horizontally
  width: 50%;
  transition: background 150ms cubic-bezier(0, 0, 0.58, 1),
    transform 150ms cubic-bezier(0, 0, 0.58, 1), bottom 0.3s ease; // Transition for bottom property

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark-pink;
    border-radius: inherit;
    box-shadow: 0 0 0 2px $pink-border, 0 0.625em 0 0 $pink-shadow;
    transform: translate3d(0, 0.75em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  }

  &:hover {
    background: $pink;
    transform: translate(0, 0.25em);
    bottom: 20%; // Move up on hover

    &::before {
      box-shadow: 0 0 0 2px $pink-border, 0 0.5em 0 0 $pink-shadow;
      transform: translate3d(0, 0.5em, -1em);
    }
  }

  &:active {
    background: $pink;
    transform: translate(0em, 0.75em);

    &::before {
      box-shadow: 0 0 0 2px $pink-border, 0 0 $pink-shadow;
      transform: translate3d(0, 0, -1em);
    }
  }
}

.item:hover .button {
  bottom: 20%; // Move up when item is hovered
}
/* Modal */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  animation: fadeIn 0.3s ease-in;
}

.modal.show {
  display: block;
}

.modal-content {
  border-radius: 8px;
  background-color: #f5f5f5;
  margin: 5% auto;
  padding: 20px; // Added padding for aesthetics
  box-sizing: border-box;
  width: 85%;
  max-width: 770px;
  max-height: 80vh; // Set a maximum height
  overflow-y: auto; // Enable vertical scrolling
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  position: relative; // Ensure the position is set to relative or absolute
  animation: scaleUp 0.3s ease-out; // Apply scale animation

  @include desktop {
    max-width: 40%;
  }
}

.scale {
  animation: scale 0.1s;
  animation-fill-mode: forwards;
}
@keyframes scaleUp {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  //   25% {
  //     transform: scale(0.5);
  //     opacity: 0.5;
  //   }
  //   50% {
  //     transform: scale(0.75);
  //     opacity: 0.75;
  //   }
  //   75% {
  //     transform: scale(0.9);
  //     opacity: 0.9;
  //   }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  //   25% {
  //     transform: scale(0.9);
  //     opacity: 0.9;
  //   }
  //   50% {
  //     transform: scale(0.75);
  //     opacity: 0.75;
  //   }
  //   75% {
  //     transform: scale(0.5);
  //     opacity: 0.5;
  //   }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.modal-content {
  animation: scaleUp 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

.modal-content.hide {
  animation: scaleDown 0.5s cubic-bezier(0.25, 0.8, 0.25, 1) forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#img {
  width: 100%;
  //   height: 450px;
}

#details {
  padding: 25px;
  text-align: left;
  position: relative;
  //   height: 264px;
  box-sizing: border-box;
  border-top: 1px solid #363638;
}

#details * {
  transition: all 0.3s;
}

#title {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

#details .button {
  align-items: center;
  color: black;
  display: flex;
  font-size: 15px;
  font-weight: 300;
  justify-content: center;
  line-height: 1em;
  //   max-width: 20%;
  min-width: 140px;
  padding: 1.5rem;
  margin: 0.2rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  background: $light-pink;
  border: 2px solid $pink-border;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);
  position: relative;
  //   bottom: 40px; // Start from bottom
  left: 25%; // Center horizontally
  width: 50%;

  position: relative;
  //   bottom: 35px; // Start from below
  left: 25%; // Center horizontally
  width: 50%;
  transition: background 150ms cubic-bezier(0, 0, 0.58, 1),
    transform 150ms cubic-bezier(0, 0, 0.58, 1), bottom 0.3s ease; // Transition for bottom property

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark-pink;
    border-radius: inherit;
    box-shadow: 0 0 0 2px $pink-border, 0 0.625em 0 0 $pink-shadow;
    transform: translate3d(0, 0.75em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  }

  &:hover {
    background: $pink;
    transform: translate(0, 0.25em);
    bottom: 20%; // Move up on hover

    &::before {
      box-shadow: 0 0 0 2px $pink-border, 0 0.5em 0 0 $pink-shadow;
      transform: translate3d(0, 0.5em, -1em);
    }
  }

  &:active {
    background: $pink;
    transform: translate(0em, 0.75em);

    &::before {
      box-shadow: 0 0 0 2px $pink-border, 0 0 $pink-shadow;
      transform: translate3d(0, 0, -1em);
    }
  }
}

#details i {
  position: absolute;
  bottom: 30px;
  right: 25px;
  font-size: 3rem;
  color: #01a2ac;
}

#details .button:hover,
i:hover {
  cursor: pointer;
  opacity: 1.8;
}

/* Close Button */
.close {
  color: #aaa;
  font-size: 23px;
  font-weight: 700;
  position: absolute;
  right: 5px;
  top: -5px;
}

.close:hover,
.close:focus {
  color: #363638;
  text-decoration: none;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust as needed */
  z-index: 101;
}

#cms,
#techStack {
  font-size: 16px;
  margin-top: 10px;
}
