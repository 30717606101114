@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.skills-header {
  display: flex;
  justify-content: center;
  @include tablet {
    margin-bottom: 5rem;
  }
  @include desktop {
    padding-top: 5rem;
  }
}

.skills-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  perspective: 1500px;
}

.skills-box {
  min-width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
  border-radius: 5%;
  margin: 10px;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: translateY(50px) rotateY(90deg);
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
  transition-delay: 0s;
  justify-content: flex-end;
}

@include tablet {
  .skills-box {
    min-width: 10%;
  }
}

@include desktop {
  .skills-box {
    max-width: 10%;
  }
}

.skills-box.animate {
  opacity: 1;
  transform: translateY(0) rotateY(0deg);
}

.skills-text {
  font-size: 1rem;
  padding: 0;
}

.skills-icon {
  width: 4rem;
  filter: saturate(190%);
}

/* Staggered 3D flip animation */
@keyframes staggered-3d-flip {
  0% {
    transform: translateY(50px) rotateY(90deg);
    opacity: 0;
  }
  100% {
    transform: translateY(0) rotateY(0deg);
    opacity: 1;
  }
}

.skills-box.animate {
  animation: staggered-3d-flip 0.5s ease forwards;
}
