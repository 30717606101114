@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

@import url("https://fonts.cdnfonts.com/css/druk-wide-bold");

/* Variables */
$bg: #fff;
$text: #382b22;
$light-pink: #fff0f0;
$pink: #ffe9e9;
$dark-pink: #f9c4d2;
$pink-border: #b18597;
$pink-shadow: #ffe3e2;

/* Global Styles */
* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

body {
  font-family: "Rubik", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-height: 100vh;
  background: $bg;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
}

/* Home Component */
.home {
  position: relative;
  height: 100vh;
  background-color: orange;
  z-index: 1;
  background-repeat: no-repeat;
  backface-visibility: visible;
  background-size: 100%;
  background-position: center;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: column-reverse;
  align-items: center;
  text-align: center;
  border: black solid 5px;

  @include tablet {
    flex-direction: row;
    justify-content: center;
  }
}

.profile_container {
  position: relative;
  top: 15%;
  z-index: 1;
  color: white;
  font-size: 30px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  gap: 50px;

  @include tablet {
    top: 30%;
    align-items: flex-end;
    text-align: left;
    margin-bottom: 2rem;
    gap: 3rem;
    left: 200px;
    height: 100%;
  }
}

.profile-header {
  font-family: "Druk Wide Bold", sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
  margin: 0;
  line-height: 1;
  font-size: 40px;
  margin-top: 15%;

  @include tablet {
    font-size: 80px;
  }
}

.profile-subheader {
  font-size: 20px;
  // width: 200px;
  text-align: center;
  color: black;
  font-weight: 200;
  position: relative;
  font-family: "Druk Wide Bold", sans-serif;
  margin: 0;

  @include tablet {
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: right;
    width: 100%;
  }
}

.bgImage {
  position: absolute;
  background-size: cover;
  background-position: top;
  z-index: -1;
}

/* Adjust banner profile photo for different screen sizes */
.banner-profilephoto {
  bottom: 0;
  height: 390px;
  position: absolute;
  transition: bottom 0.1s ease;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include tablet {
    // height: 500px;
    height: 70%;
    bottom: 0;
    position: absolute;
    left: -25%;
    transform: unset;
  }

  img {
    height: 100%;
  }
}

/* Profile Button styles for different screen sizes */
.profile-button {
  align-items: center;
  border: 0;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-size: 15px;
  font-weight: 300;
  justify-content: center;
  line-height: 1em;
  max-width: 33%;
  padding: 3px;
  margin: 0.2rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;

  span {
    padding: 12px 15px;
    border-radius: 69px;
    width: 100%;
    height: 100%;
    transition: 300ms;
    background: linear-gradient(45deg, #a4b5d2, #8a98b0);
    box-shadow: 29px -29px 58px #4e5664, -29px 29px 58px #e4fcff;
  }

  @include tablet {
    max-width: unset;
    width: auto;
  }
}

.profile-button__container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;

  @include tablet {
    position: relative;
    bottom: 3rem;
  }
}

/* Learn More Button styles for different screen sizes */
button.learn-more {
  font-weight: 600;
  color: $text;
  text-transform: uppercase;
  padding: 1em 1em;
  background: $light-pink;
  border: 2px solid $pink-border;
  border-radius: 0.75em;
  transform-style: preserve-3d;
  transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
    background 150ms cubic-bezier(0, 0, 0.58, 1);

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $dark-pink;
    border-radius: inherit;
    box-shadow: 0 0 0 2px $pink-border, 0 0.625em 0 0 $pink-shadow;
    transform: translate3d(0, 0.75em, -1em);
    transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
      box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
  }

  &:hover {
    background: $pink;
    transform: translate(0, 0.25em);

    &::before {
      box-shadow: 0 0 0 2px $pink-border, 0 0.5em 0 0 $pink-shadow;
      transform: translate3d(0, 0.5em, -1em);
    }
  }

  &:active {
    background: $pink;
    transform: translate(0em, 0.75em);

    &::before {
      box-shadow: 0 0 0 2px $pink-border, 0 0 $pink-shadow;
      transform: translate3d(0, 0, -1em);
    }
  }

  @include tablet {
    padding: 1.25em 2em;
  }
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 24px;
    min-width: 196px;
  }
}
