@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.footer {
  background-color: orange;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: black solid 5px;
}

.footer-text {
  color: black;
  padding: 0.2rem;
  margin: 1rem;
  text-align: center;
  height: 100%;
}

.footer-text img {
  max-height: 30px;
  padding: 5%;
  margin-right: 5px;
}
