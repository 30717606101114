@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-ExtraLight.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Light.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 200;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Regular.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Medium.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-SemiBold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-Bold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: Karla;
  src: url(../../assets/Font/Karla/static/Karla-ExtraBold.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 800;
}

@font-face {
  font-family: parklane;
  src: url(../../assets/Font/parklane/ParkLaneNF.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: coventrygarden;
  src: url(../../assets/Font/coventrygarden/CoventryGardenNF.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: superdream;
  src: url(../../assets/Font/super-dream-font/SuperDream-ax3vE.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: remachine;
  src: url(../../assets/Font/remachine-script-font/RemachineScriptPersonalUseOnly-yZL3.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: freshman;
  src: url(../../assets/Font/freshman-font/Freshman-POdx.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: archer;
  src: url(../../assets/Font/archer/ARCHER.TTF);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

@font-face {
  font-family: monument;
  src: url(../../assets/Font/MonumentExtended-FreeForPersonalUse/MonumentExtended-Regular.otf);
  font-style: normal;
  font-display: swap;
  font-weight: 100;
}

// @font-face {
//   font-family: druk;
//   src: url(../../assets/Font/druk/druk.ttf);
//   font-style: normal;
//   font-display: swap;
//   font-weight: 100;
// }
