@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #fff;
  border: black solid 5px;
}

.navbar-list {
  display: flex;
  justify-content: center;
  list-style: none;
  font-weight: 700;
  padding: 0;
  font-size: 15px;
  text-align: center;

  @include tablet {
    font-size: 20px;
    font-weight: bold;
  }
}

.navbar-list__item {
  cursor: pointer;
  padding: 0.5rem;
  color: #3b3b3c;
}
.navbar-list__item:hover {
  color: #ffc892;
}
