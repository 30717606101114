@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/typography" as *;
@use "../../styles/partials/variables" as *;

.contact {
  background-color: #ffffff82;
  padding-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;

  @include tablet {
    width: 100%;
  }
}

Form {
  // background-image: url("../../assets/Images/blobanimation\ \(1\).svg");
  background-position: 30%;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#form-input-control-email {
  background-color: #ffffff;
  border: 2px solid rgb(255 165 0);
  margin-bottom: 2rem;
  width: 20rem;
  height: 2rem;
  border-radius: 0.3rem;
  padding: 0.5rem;
}

#form-input-control-last-name {
  background-color: #ffffff;
  border: 2px solid rgb(255 165 0);
  margin-bottom: 2rem;
  width: 20rem;
  height: 2rem;
  border-radius: 0.3rem;
  padding: 0.5rem;
}

#form-textarea-control-opinion {
  background-color: #ffffff;
  border: 2px solid rgb(255 165 0);
  margin-bottom: 2rem;
  width: 20rem;
  border-radius: 0.3rem;
  height: 9rem;
  padding: 0.5rem;
}

.profile-button.contact-button {
  /* Add or override styles as needed */
  /* Example styles from the previous button */
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  font-family: "Karla", Times, serif;
  box-shadow: rgba(255, 255, 255, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  font-weight: 300;
  justify-content: center;
  line-height: 1em;
  min-width: 140px;
  padding: 3px;
  margin: 0.2rem;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.profile-button.contact-button:hover span {
  background: none;
}

input::placeholder {
  font-family: Karla;
  padding-left: 0.5rem;
}

textArea::placeholder {
  font-family: Karla;
  padding-left: 0.5rem;
}

.custom-textarea {
  border: 1px solid #ffffff;
  background-color: #fbd1d1;
  padding: 8px;
}

.contact-header {
  margin-bottom: 2rem;
}

.contact-title {
  margin: 1rem;
}

.contact-networks {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  padding-right: 1rem;
  height: 5rem;
  @include tablet {
    flex-direction: row;
  }
}

.contact-networks__text {
  display: flex;
  justify-content: center;
}

.contact-linkedin {
  width: 5rem;
  cursor: pointer;
}

.contact-gmail {
  width: 6rem;
  cursor: pointer;
}
.contact-calendly {
  width: 4rem;
  padding-top: 1.5rem;
  cursor: pointer;
}
